 
export const USER_TYPE = {
    INSURER: 'insurer',
    PATIENT: 'my',
    COMPANY: 'company',
    CLINIC: 'clinic',
};

export const AppRoutes = {
    Index: '/',
    INSURER: {
        INSIGHTS: `/${USER_TYPE.INSURER}/insights`,
        SPONSORS: `/${USER_TYPE.INSURER}/sponsors`,
        TRANSACTIONS: `/${USER_TYPE.INSURER}/transactions`,
        BENEFICIARIES: `/${USER_TYPE.INSURER}/beneficiaries`,
        APPOINTMENTS: `/${USER_TYPE.INSURER}/appointments`,
        SETTINGS: `/${USER_TYPE.INSURER}/settings`,
        VISITS: `/${USER_TYPE.INSURER}/visits`,
        USERS: {
            NEW: `/${USER_TYPE.INSURER}/users/new`,
            INDEX: `/${USER_TYPE.INSURER}/users`,
        }
    },
    PATIENT: {
        INSIGHTS: `/${USER_TYPE.PATIENT}/insights`,
        TRANSACTIONS: `/${USER_TYPE.PATIENT}/transactions`,
        // Add more patient-specific URLs as needed
    },
    COMPANY: {
        BENEFICIARIES: `/${USER_TYPE.COMPANY}/beneficiaries`,
        // Add more company-specific URLs as needed
    },
    CLINIC: {
        BENEFICIARIES: `/${USER_TYPE.CLINIC}/beneficiaries`,
        // Add more clinic-specific URLs as needed
    },
    Dashboard: {
        Index: '/dashboard'
    },
    Profile: {
        Select: "/profile/select",
        NoProfiles: "/profile/no-profiles",
    },
    Auth: {
        Login: "/login",
        Logout: "/logout",
        Callback: "/callback",
        Error: "/error",
        Register: "/register",
        ResetPassword: "/reset-password",
        ForgotPassword: "/forgot-password",
    },
    Emr: {
        Dashboard: "/dashboard/",
    },
    Patient: {
        Dashboard: "/patient/dashboard",
        Settings: "/patient/settings",
        Profile: "/patient/profile",
        NoProfiles: "/patient/no-profiles",
        Select: "/patient/select",
        Appointments: "/patient/appointments",
        Transactions: "/patient/transactions",
        Insights: "/patient/insights",
        Visits: "/patient/visits",
        Bills: "/patient/bills",
        Claims: "/patient/claims",
        Providers: "/patient/providers",
        Messages: "/patient/messages",
        TestResults: "/patient/test-results",
        Medications: "/patient/medications",
        FindCare: "/patient/find-care",
        SymptomChecker: "/patient/symptom-checker",
        TalkToDoctor: "/patient/talk-to-doctor",
        Schedule: "/patient/schedule",
        EVisit: "/patient/e-visit",
        CareTeam: "/patient/care-team",
        MyRecord: "/patient/my-record",
        Communications: "/patient/communications",
    }
};
